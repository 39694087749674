.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  font-family: 'Big Shoulders Text', cursive;
  background-color: #030512;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-header img {
  max-width: 30%;
  max-height: 30%;
}
